import React, { useEffect, useState } from "react";
import app from "gatsby-plugin-firebase-v9.0";
import { getDatabase, ref, set, onValue } from "firebase/database";
import { getAuth, signInAnonymously } from "firebase/auth";
import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import Hero from "../components/Templates/Hero";
import ImageWoodies from "../images/Line-Up-Woodies.png";
import ImageLeftBank from "../images/Line-Up-LeftBank.png";
import ImageSummerhill from "../images/Line-Up-Summerhill.png";
import Link from "../components/Global/Link";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Markup
const Schedule = ({ postID }) => {
  const openGraph = {
    type: "website",
    title: "Weekend Schedule | CP4RP",
    socialTitle: "All the details for Claire & Richy's big day",
    description: "20th May 2023 | Left Bank, Leeds",
  };

  let accountId = null;

  if (typeof window !== "undefined") {
    accountId = localStorage.getItem("guest");
  }
  const [dataAll, setDataAll] = useState("");
  const [family, setFamily] = useState(false);
  const [rsvp, setRsvp] = useState(false);

  const [firebaseUID, setFirebaseUID] = useState(null);
  const [noData, setNoData] = useState(false);

  useEffect(() => {
    const database = getDatabase(app);
    const auth = getAuth();
    signInAnonymously(auth)
      .then(() => {
        // Signed in..
        // Get all data
        setFirebaseUID(auth.currentUser.uid);
        const fbQuery = ref(database, "people/" + accountId);
        onValue(fbQuery, (snapshot) => {
          const data = snapshot.val();
          setDataAll(data);
          if (data) {
            // Check if they are family or not
            if (data.family === true) {
              setFamily(true);
            }
            if (data.rsvp) {
              setRsvp(true);
            }
          }
          if (!data) {
            setNoData(true);
          }
        });
      })
      .catch((error) => {
        alert("There was an error getting the data");
      });
  }, [postID]);

  if (!dataAll)
    return (
      <>
        <Hero title="Schedule" colour="pink" />
        <section className="py-5 brand-background-black brand-section-min-height">
          <div className="container text-center py-5 brand-text-white">
            <FontAwesomeIcon icon="spinner" size="3x" spin pulse />
          </div>
        </section>
      </>
    );

  return (
    <Layout>
      <Meta openGraph={openGraph} />
      <Hero title="Schedule" colour="pink" />
      <section className="py-5 brand-background-black">
        <div className="container">
          <div className="brand-background-white p-4 p-md-5 rounded mb-5">
            <h3 className="brand-font brand-responsive">Woodies Warm-Up</h3>
            <h4 className="brand-font">Friday 19th May</h4>
            <p className="small">No need to RSVP, just turn up!</p>
            <hr />
            <p>
              We've reserved an area in our favourite local. This is your last
              chance to snog Claire Pittaway before she's married off, so get
              there early.
            </p>

            <ul className="list-unstyled">
              <li className="py-2">
                <FontAwesomeIcon icon="clock" className="me-2" />
                From <strong>5pm</strong>
              </li>
              <li className="py-2">
                <FontAwesomeIcon icon="beer" className="me-2" />
                Bar tab proudly sponsored by Mick and Sarah Pittaway
              </li>
              <li className="py-2">
                <FontAwesomeIcon icon="utensils" className="me-2" />
                Food served until 9pm, great kids options available
              </li>
              <li className="py-2">
                <FontAwesomeIcon icon="child" className="me-2" />
                Kids welcome until 8pm (not our rule)
              </li>
            </ul>

            <div className="d-flex border rounded p-4">
              <div class="flex-shrink-0">
                <img
                  src={ImageWoodies}
                  alt="Woodies Craft Ale House"
                  width="120"
                />
              </div>
              <div className="flex-grow-1 ms-3">
                <h6>Woodies Craft Ale House</h6>
                <p className="mb-0 small">
                  104 Otley Rd
                  <br />
                  Headingley
                  <br />
                  Leeds
                  <br />
                  LS16 5JG
                </p>
              </div>
              <div class="flex-shrink-0">
                <ul className="list-unstyled text-end">
                  <li className="py-2">
                    <Link
                      to="https://www.google.com/maps?daddr=Woodies+Craft+Ale+House,+Otley+Road,+Headingley,+Leeds/"
                      icon="directions"
                    >
                      Directions
                    </Link>
                  </li>
                  <li className="py-2">
                    <Link
                      to="https://www.greeneking-pubs.co.uk/pubs/west-yorkshire/woodies-craft-ale-house/"
                      icon
                    >
                      Website
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="brand-background-white p-4 p-md-5 rounded mb-5">
            <h3 className="brand-font brand-responsive brand-text-pink">
              The Big Day
            </h3>
            <h4 className="brand-font">Saturday 20th May</h4>
            {rsvp === false && (
              <p>
                <Link to="/rsvp/" className="small brand-text-pink">
                  RSVP required
                </Link>
              </p>
            )}
            <hr />
            <p>
              Canapes and drinks on arrival, with dinner served from about 4pm -
              so be sure to eat breakfast in the morning.
            </p>
            <p>Evening food will also be served from 8pm.</p>
            <p>
              You can expect a full day of entertainment, heart-felt speeches,
              live music and dodgy dance moves!
            </p>
            <ul className="list-unstyled">
              <li className="py-2">
                <FontAwesomeIcon icon="clock" className="me-2" />
                Please arrive between <strong>2 - 2:30pm</strong>
              </li>
              <li className="py-2">
                <FontAwesomeIcon icon="tshirt" className="me-2" />
                Wear your best outfit. Suit and tie not mandatory.
              </li>
              <li className="py-2">
                <FontAwesomeIcon icon="car" className="me-2" />
                Limited parking at the venue but plenty of on free on-street
                parking nearby
              </li>
              <li className="py-2">
                <FontAwesomeIcon icon="utensils" className="me-2" />
                Mexican fiesta! (hot dogs for the kids)
              </li>
              <li className="py-2">
                <FontAwesomeIcon icon="pizza-slice" className="me-2" />
                Pizza van from 8pm
              </li>
              <li className="py-2">
                <FontAwesomeIcon icon="music" className="me-2" />
                80s glam rock band, Yorkshire brass band and two handsome DJs
                spinning Ibiza classics
              </li>
              <li className="py-2">
                <FontAwesomeIcon icon="link" className="me-2" />
                <Link to="/lineup/">Check out the full line-up</Link>
              </li>
            </ul>
            <div className="text-start mb-5">
              {rsvp ? (
                <ul className="list-unstyled">
                  <li className="py-2">
                    <FontAwesomeIcon icon="check" className="me-2" />
                    <strong>RSVP submitted</strong>
                  </li>
                </ul>
              ) : (
                <p>
                  <Link button role="button" to="/rsvp/" icon="check">
                    RSVP now
                  </Link>
                </p>
              )}
            </div>
            <div className="d-flex border rounded p-4">
              <div class="flex-shrink-0">
                <img src={ImageLeftBank} alt="Left Bank Leeds" width="120" />
              </div>
              <div className="flex-grow-1 ms-3">
                <h6>Left Bank Leeds</h6>
                <p className="mb-0 small">
                  Cardigan Rd
                  <br />
                  Burley
                  <br />
                  Leeds
                  <br />
                  LS6 1LJ
                </p>
              </div>
              <div class="flex-shrink-0">
                <ul className="list-unstyled text-end">
                  <li className="py-2">
                    <Link
                      to="https://www.google.com/maps?daddr=Left+Bank+Leeds,+Cardigan+Rd,+Burley,+Leeds+LS6+1LJ"
                      icon="directions"
                    >
                      Directions
                    </Link>
                  </li>
                  <li className="py-2">
                    <Link to="https://www.leftbankleeds.org.uk" icon>
                      Website
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {family === true && (
            <div className="brand-background-white p-4 p-md-5 rounded mb-5">
              <h3 className="brand-font brand-responsive">Summerhill Sunday</h3>
              <h4 className="brand-font">Sunday 21st May</h4>
              <p className="small">No need to RSVP, just turn up!</p>
              <hr />
              <p>
                <span className="badge brand-background-pink">
                  A family only event
                </span>
              </p>
              <p>
                Join us for Bloody Marys and sausage sarnies (veg options
                available) to cure the hangover, at our new house in Headingley
                - we'd love to spend our first day as a married couple with you!
              </p>
              <ul className="list-unstyled">
                <li className="py-2">
                  <FontAwesomeIcon icon="clock" className="me-2" />
                  From <strong>11am</strong>
                </li>
                <li className="py-2">
                  <FontAwesomeIcon icon="car" className="me-2" />
                  Free street parking outside the house
                </li>
              </ul>
              <div className="d-flex border rounded p-4">
                <div class="flex-shrink-0">
                  <img src={ImageSummerhill} alt="Our Gaff" width="120" />
                </div>
                <div className="flex-grow-1 ms-3">
                  <h6>Summerhill</h6>
                  <p className="mb-0 small">
                    9 Weetwood Avenue
                    <br />
                    Weetwood
                    <br />
                    Leeds
                    <br />
                    LS16 5JG
                  </p>
                </div>
                <div class="flex-shrink-0">
                  <ul className="list-unstyled text-end">
                    <li className="py-2">
                      <Link
                        to="https://www.google.com/maps?daddr=9+Weetwood+Avenue,+Weetwood,+Leeds+LS16+5NG,+UK"
                        icon="directions"
                      >
                        Directions
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </Layout>
  );
};

export default Schedule;
